import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { AuthContextType, User } from './AuthTypes';

export const AuthContext = createContext<AuthContextType>({
    user: null,
    token: null,
    login: () => Promise.resolve(),
    signup: () => Promise.resolve(),
    logout: () => {},
});

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [token, setToken] = useState<string | null>(localStorage.getItem('jwt') || null);

    // Check local storage for JWT at app start
    useEffect(() => {
        if (token) {
            axios
                .get<User>('/api/user', { headers: { Authorization: `Bearer ${token}` } })
                .then((response) => setUser(response.data))
                .catch(() => logout()); // Invalid token, force logout
        }
    }, [token]);

    // Login function
    const login = async (email: string, password: string) => {
        try {
            const response = await axios.post<{ jwt: string; userData: User }>('/api/login', { email, password });
            const { jwt, userData } = response.data;
            setToken(jwt);
            setUser(userData);
            localStorage.setItem('jwt', jwt);
        } catch (error) {
            console.error('Login error:', error);
            // Handle error as needed
        }
    };

    // Signup function
    const signup = async (email: string, password: string) => {
        try {
            const response = await axios.post<{ jwt: string; userData: User }>('/api/signup', { email, password });
            const { jwt, userData } = response.data;
            setToken(jwt);
            setUser(userData);
            localStorage.setItem('jwt', jwt);
        } catch (error) {
            console.error('Signup error:', error);
            // Handle error as needed
        }
    };

    // Logout function
    const logout = () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('jwt');
    };
    return <AuthContext.Provider value={{ user, token, login, signup, logout }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
