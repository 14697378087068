import { StyledEngineProvider } from '@mui/joy/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './hooks/AuthProvider';
import setupAxios from './setup/SetupAxios';

setupAxios(() => {
    // Do something when the user is not authorized
    console.error('User is not authorized');
});

ReactDOM.createRoot(document.querySelector('#root')!).render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <BrowserRouter>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </BrowserRouter>
        </StyledEngineProvider>
    </React.StrictMode>
);
