import { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// lazy load each container
const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const Forgot = lazy(() => import('./pages/auth/Forgot'));
const DashboardRouter = lazy(() => import('./pages/router'));

export default function App() {
    const location = useLocation();

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes key={location.pathname} location={location}>
                <Route path="/sign-in" element={<Login />} />

                <Route path="/sign-up" element={<Register />} />

                <Route path="/forgot-password" element={<Forgot />} />

                {/* Include nested subroutes */}
                <Route path={`*`} element={<DashboardRouter />} />
            </Routes>
        </Suspense>
    );
}
